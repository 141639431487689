<template>
  <div  v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="orderShopList-container">
    <div class="common-Summary-container">
      <div class="Summary-item">
        <div class="item-title">协议子账号数</div>
        <div @click="
            () => {
              collect(
                'subAccountNum',
                '协议子账号数',
                [
                  { name: '子账号名称', type: 'input', modelKey: 'subAccountName' },
                  { name: '店铺名称', type: 'input', modelKey: 'shopName' },
                ],
                [
                  { name: '子账号名称', colProp: ['subAccountName'] },
                  { name: '子账号密码', colProp: ['subAccountPassword'] },
                  { name: '指派客服', colProp: ['nickName'] },
                  { name: '所属店铺', colProp: ['shopName'] },
                  { name: '子账号服务时间', colProp: ['startTime', 'endTime'] }
                ]
              );
            }
          "
           class="item-num">{{ headInfo.subAccountNum || "0" }}</div>
      </div>
      <div class="Summary-item">
        <div class="item-title">当前在线子账号数</div>
        <div @click="
            () => {
              collect(
                'subAccountLoginNum',
                '当前在线子账号数',
                [
                  { name: '子账号名称', type: 'input', modelKey: 'subAccountName' },
                  { name: '店铺名称', type: 'input', modelKey: 'shopName' },
                ],
                [
                  { name: '子账号名称', colProp: ['subAccountName'] },
                  { name: '子账号密码', colProp: ['subAccountPassword'] },
                  { name: '指派客服', colProp: ['nickName'] },
                  { name: '所属店铺', colProp: ['shopName'] },
                  { name: '子账号服务时间', colProp: ['startTime', 'endTime'] }
                ]
              );
            }
          "
           class="item-num">{{ headInfo.subAccountLoginNum || '0' }}</div>
      </div>
      <div class="Summary-item">
        <div class="item-title">3天内服务到期的店铺</div>
        <div @click="
            () => {
              collect(
                'expireShopNum',
                '3天内服务到期的店铺',
                [
                  { name: '店铺名称', type: 'input', modelKey: 'shopName' },
                  { name: '客户名称', type: 'input', modelKey: 'customerName' },
                ],
                [
                  { name: '店铺名称', colProp: ['shopName'] },
                  { name: '客户名称', colProp: ['customerName'] },
                  { name: '服务时间', colProp: ['startTime', 'endTime'] },
                ]
              );
            }
          "
          class="item-num">{{ headInfo.expireShopNum || "0" }}</div>
      </div>
      <!-- <div class="Summary-item">
        <div class="item-title">累计接单金额</div>
        <div class="item-num">{{ headInfo.totalPrice || '0' }}</div>
      </div> -->
    </div>
    <div class="common-screen-container">
      
      <div class="common-input-container">
        <span>店铺名称:</span>
        <el-input
          class="common-screen-input"
          placeholder="请输入"
          v-model="params.shopName"
        ></el-input>
      </div>
      <div v-if="!$vuex.state.onlyCompany" class="common-input-container">
        <span>公司名称:</span>
        <el-select class="common-screen-input" @change="commonFun" v-model="params.companyId" placeholder="请选择" filterable >
            <el-option v-for="item in rolesCompany" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </div>
      <div class="common-input-container">
        <span>客户名称:</span>
        <el-input
          class="common-screen-input"
          placeholder="请输入"
          v-model="params.customerName"
        ></el-input>
      </div>
      <div class="common-input-container">
        <span>所属平台:</span>
        <el-select class="common-screen-input" v-model="params.platformId" placeholder="所属平台" @change="companyFun" clearable filterable >
          <el-option v-for="item in platList" :key="item.id" :label="item.platformName" :value="item.id" > </el-option>
        </el-select>
      </div>
      <div class="common-input-container">
        <span>所属类目:</span>
        <el-select class="common-screen-input" v-model="params.categoryId" placeholder="所属类目" @change="companyFun" clearable filterable remote :remote-method="(val)=>{remoteCategory(val)}" @focus="selectClear">
          <el-option v-for="item in categoryList" :key="item.id" :label="item.platformName" :value="item.id" > </el-option>
          <div class="common-select-page">
            <div v-if="categoryParams.pageNum != 1" @click="() => {selectPage('up')}">上一页</div>
            <div v-if="categoryParams.pageNum == 1" style="color: #999">上一页</div>
            <div v-if="categoryList && categoryList.length == 10" @click="() => {selectPage('down')}">下一页</div>
            <div v-if="categoryList && categoryList.length < 10" style="color: #999">下一页</div>
          </div>
        </el-select>
      </div>
      <div class="common-input-container" style="margin-bottom: 0;">
          <div class="common-input-container">
            <span style="margin-right: 16px;">创建时间:</span>
            <div class="common-input-container">
               <common-date @commonGetDate="commonGetDate" :radio="commonRadio" :startDate="commonStartDate"  :endDate="commonEndDate" ref="commonReset"></common-date>
            </div>
          </div>
          <div class="common-input-container">
              <el-button class="common-screen-btn" type="primary" @click="() => orderShopList()" >查 询</el-button>
              <el-button class="common-screen-btn" plain @click=" () => { resetBtn()} " >重 置</el-button>
          </div>
      </div>
      <!-- <div>
        <el-button class="common-screen-btn" type="primary" @click="() => orderShopList()" >查 询</el-button>
        <el-button class="common-screen-btn" plain @click=" () => { resetBtn()} " >重 置</el-button>
      </div> -->
    </div>
    <el-table class="common-table" :data="tableData" :fit="true">
      <el-table-column width="80px">
        <template slot-scope="scope">
          <span>
            <img
              class="common-iconImg"
              v-if="scope.row.shopLogo"
              :src="scope.row.shopLogo"
            />
            <span v-if="!scope.row.shopLogo"
              ><i class="el-icon-user common-iconImg-MR"></i
            ></span>
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="shopName" label="店铺名称">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="() => shopDetailShow(scope.row)">{{scope.row.shopName || '--'}}</el-button>
        </template>
      </el-table-column>
      <el-table-column
        prop="customerName"
        label="客户名称"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="platformName"
        label="所属平台"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="categoryName"
        label="所属类目"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column width="220px" label="协议客服工作时长" >
        <template slot-scope="scope">
          <div>{{`${scope.row.agreeStartTime}~${scope.row.agreeEndTime || '00:00:00'} (${scope.row.serviceWorkTime || 0}小时)`}}</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="agreeSubNum"
        label="协议子账号数"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="serviceContentName"
        label="服务内容"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column label="服务时间">
        <template slot-scope="scope">
          <div>{{`${scope.row.startTime}\n${scope.row.endTime}`}}</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="price"
        label="派单金额"
        :formatter="tableColumn"
      ></el-table-column>
    
      <el-table-column label="操作" width="120" fixed="right">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="() => {
                $router.push({
                  path: '/receive-order/subaccount-list',
                  query: {
                    shopId: scope.row.shopId,
                    shopName: scope.row.shopName,
                    categoryName: scope.row.categoryName,
                    platformName: scope.row.platformName,
                    customerName: scope.row.customerName,
                    startTime: scope.row.startTime,
                    endTime: scope.row.endTime,
                    shopLogo: scope.row.shopLogo,
                    fileUrl: scope.row.fileUrl,
                    isAssign: 1
                  },
                });
              }"
            >子账号管理</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="(e) => pageFun(e, 'pageSize')"
      @current-change="(e) => pageFun(e)"
      style="text-align: center"
      :page-sizes="pagination.pageSizes"
      :page-size="params.pageSize"
      :current-page="params.pageNum"
      layout="total, prev, pager, next,sizes, jumper"
      :total="pagination.total"
    >
    </el-pagination>
    <shop-detail ref="shopDetail" :drawer="drawer" @handleClose="handleClose"></shop-detail>
    <!-- 头部报表弹 -->
    <common-sum-dialog ref="commonSumDialog" :methodFuc="orderSumList" :commonSumVisible="commonSumVisible" @handleClose="sumVisibleClose"></common-sum-dialog>
  </div>
</template>
<script>
import ShopDetail from "./shopDetail.vue"
import CommonDate from "../../../components/common/commonDate.vue"
import { getCustList, selectPlatform, selectCategory } from "../../../service/common.js";
import { orderShopList, orderSumList } from "../../../service/assignOrder.js";
import { Config, tableColumn, DateTransform } from "../../../utils/index.js";
import CommonSumDialog from "../../../components/common/commonSumDialog.vue"
export default {
  components: {
    ShopDetail,
    CommonSumDialog,
    CommonDate
  },
  data() {
    return {
      increasing: 1,
      tableColumn,
      loading: false,
      pagination: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100],
      },
      params: {
        pageNum: 1,
        pageSize: 10,
        startDate: DateTransform(new Date),
        endDate: DateTransform(new Date),
      },
      finishedList: [], // 所属客户下拉
      platList: [], // 所属平台
      categoryList: [], //类目下拉
      categoryParams: {
        pageNum: 1,
        pageSize: 10,
        name: ''
      },
      tableRow: "", // 客户id用于编辑/查看
      rolesCompany: this.$sto.get(Config.constants.userInfo).roles_company, // 所属公司下拉
      selectDepart: [],
      centerDialogVisible: false, // 修改密码员工弹窗
      drawer: false, // 新增员工弹窗
      roleSelect: "",
      tableData: [],
			headInfo: {

      },
      //////////////////////////
      headerType: "",
      dialogTit: "",
      dialogVisible2: false,
      paramsTit: [],
      childColName: [],
      commonSumVisible: false,
      orderSumList, // 列表接口方法
      commonRadio: 1,
      commonStartDate: DateTransform(new Date),
      commonEndDate: DateTransform(new Date),
    };
  },
  created() {
    if (this.rolesCompany) {
      this.params.companyId = this.rolesCompany[0].id;
    }
   if(this.$route.query.from && this.$route.query.shopName) {
      this.params.shopName = this.$route.query.shopName
      delete this.params.startDate
      delete this.params.endDate
      this.commonStartDate = ''
      this.commonEndDate = ''
      this.commonRadio = null
    }
  },
  mounted() {
    if(this.$route.query.from && this.$route.query.shopName && this.$route.query.shopId && this.$route.query.disOrderNo) {
      this.shopDetailShow({shopId: this.$route.query.shopId, disOrderNo: this.$route.query.disOrderNo})
    }
    this.orderShopList();
    this.selectPlatform();
    this.selectCategory();
  },
  methods: {
    commonGetDate (startDate, endDate) { // 时间筛选
        this.params.startDate = startDate
        this.params.endDate = endDate
        this.orderShopList()
    },
    // 数据汇总弹窗-start
    sumVisibleClose () {
        this.commonSumVisible = false
    },
    collect(type, name, screen, arr) { // 汇总弹窗-子组件参数 type：弹窗类型，name：弹窗标题，screen：筛选，arr：表格数据
        // this.$refs.commonSumDialog.getAllData(type, name, screen, arr);
        this.$refs.commonSumDialog.getAllData(type, name, screen, arr, this.params.companyId);
        this.commonSumVisible = true;
    },
    // 数据汇总弹窗 -end
    selectClear () { // 下拉清空时触发
      this.categoryParams.pageNum = 1
      this.categoryParams.name = ''
      this.selectCategory()
    },
    selectPage(type) { // 下拉分页
      if (type == 'up') {
        this.categoryParams.pageNum--
      } else {
        this.categoryParams.pageNum++
      }
      this.selectCategory()
    },
    remoteCategory(val) { // 所属类目下拉-筛选
      this.categoryParams.pageNum = 1
      this.categoryParams.name = val
      this.selectCategory()
    },
    async selectCategory() { // 类目下拉
      let categoryParams = {...this.categoryParams}
      let { data } = await selectCategory(categoryParams)
      this.categoryList = data.list
    },
    async selectPlatform() { // 平台下拉
      let { data } = await selectPlatform({pageNum: -1})
      this.platList = data
    },
     remoteMethod (val, name) {
      if (name == 'custList') {
        this.getCustList(val)
      }
    },
    commonFun() {
      this.params.pageNum = 1;
      this.params.pageSize = 10;
      this.orderShopList();
    },
    async getCustList(name) {
      // 所属客户下拉
      let resData = (await getCustList({customerName: name})).data;
      this.finishedList = resData;
    },
    resetBtn() {
      this.$refs.commonReset.resetFun(this.commonRadio, this.commonStartDate, this.commonEndDate)
      this.params = {
        pageNum: 1,
        pageSize: 10,
        companyId: this.rolesCompany[0].id,
        startDate: DateTransform(new Date),
        endDate: DateTransform(new Date),
      };
      this.orderShopList();
    },
    pageFun(e, type) {
      // 分页
      if (type == "pageSize") {
        this.params.pageSize = e;
      } else {
        this.params.pageNum = e;
      }
      this.orderShopList();
    },
    async orderShopList() {
      let params = { ...this.params };
      this.loading = true;
      let { data } = await orderShopList(params);
      this.loading = false;
      this.pagination.total = data.total;
      this.pagination.pageSize = data.size;
			this.todayNum = data.today;
      this.tableData = data.records;
      this.headInfo = {
        subAccountNum: data.subAccountNum,
        subAccountLoginNum: data.subAccountLoginNum,
        expireShopNum: data.expireShopNum,
        totalPrice: data.totalPrice,
      }
      
    },
    companyFun() {
      //选择公司筛选
      this.params.departId = [];
      this.orderShopList();
    },
    handleClose() {
      this.drawer = false;
      this.orderShopList();
    },
    async shopDetailShow(row) { // 店铺详情
      if (row) {
        this.$refs["shopDetail"].getDataInfo(row);
        this.drawer = true;
      } else {
        this.$message.error("系统异常")
      }
    },
  },
};
</script>
<style lang="less" scoped>
.orderShopList-container {
  text-align: left;
  .common-screen-container {
    padding-top: 32px;
  }
  /deep/ .el-button--text {
    white-space: normal;
    line-height: 20px;
    text-align: left;
  }
  .nameIcon {
    width: 32px;
    height: 32px;
    color: #f56a00;
    background-color: #fde3cf;
    border-radius: 50%;
    text-align: center;
    line-height: 32px;
  }
}
.dialog-input {
  width: 320px !important;
}
.el-cascader-menu__empty-text::after {
  content: "请先选择公司";
}
</style>
